import Axios from 'axios';
import FormatTime from '../filters/format_time';
import NoImage from '../../images/front/blog-no-eye-catch.jpg';

const MAX_RETRY_COUNT = 2;

export default {
  props: {
    path:         { type: String, required: false, default: '' },
    parameters:   { type: Array, default: (() => []) },
    numOfDisplay: { type: Number, default: 3 },
    taxonomy:     { type: String, default: 'category' },
    feedType:     { type: String, required: false, default: 'taxonomy' },
  },
  created() { this.get_feed(); },
  data() {
    return {
      feeds:      [],
      retryCount: 0,
    };
  },
  computed: {
    getFeedType() {
      const allowedFeedType = ['taxonomy', 'rss', 'wp-json'];
      if (allowedFeedType.indexOf(this.feedType) >= 0) {
        return this.feedType;
      }
      return null;
    },
    requestUrl() {
      const p = this.parameters.length > 0 ? `${this.parameters.join('&')}` : '';

      if (this.getFeedType === 'taxonomy') return `/blog/archives/${this.taxonomy}/${this.path}?feed=rss2&${p}`;
      if (this.getFeedType === 'rss') return `/blog?feed=rss2&${p}`;
      if (this.getFeedType === 'wp-json') return `/blog/wp-json/wp/v2/posts?${p}`;
      return '';
    },
    responseType() {
      if (this.getFeedType === 'wp-json') return 'json';
      return 'document';
    },
  },
  methods: {
    get_feed() {
      if (this.requestUrl === '') return [];

      return Axios.get(`${this.requestUrl}`, {
        responseType: this.responseType,
      })
        .then((res) => {
          if (this.getFeedType === 'taxonomy' || this.getFeedType === 'rss') this.parseFeeds(res);
          if (this.getFeedType === 'wp-json') this.parseWpJson(res);
        })
        .catch((res) => {
          if (res.status < 501) { return; }
          if (MAX_RETRY_COUNT < this.retryCount) { return; }

          this.retryCount += 1;
          this.get_feed();
        });
    },
    // for ?feed=rss2
    parseFeeds(res) {
      this.feeds = Array.from(res.data.getElementsByTagName('item')).slice(0, this.numOfDisplay).map((item) => {
        const thumbnailUrl = item.getElementsByTagName('thumbnailUrl')[0].textContent;
        const labels = $(item.getElementsByTagName('categoryLabel')[0].children).map(
          (index, el) => (
            { name: el.textContent }
          ),
        );

        return {
          title:       item.getElementsByTagName('title')[0].textContent,
          image:       thumbnailUrl.length > 0 ? thumbnailUrl : NoImage,
          description: this.removeHtmlTag(item.getElementsByTagName('description')[0].textContent),
          labels,
          slug:        item.getElementsByTagName('categorySlugs')[0].childNodes,
          link:        item.getElementsByTagName('link')[0].textContent,
          pubDate:     item.getElementsByTagName('pubDate')[0].textContent,
        };
      });
    },
    // for wp-json/v2/posts
    parseWpJson(res) {
      this.feeds = res.data.slice(0, this.numOfDisplay).map((item) => {
        /* eslint-disable no-underscore-dangle */
        const media = item._embedded['wp:featuredmedia'][0];

        return {
          title:       item.title.rendered,
          image:       this.getThumbnailUrl(media),
          altText:     media?.alt_text ? media.alt_text : item.title.rendered,
          description: item.excerpt.rendered,
          labels:      item._embedded['wp:term'][0].map((el) => ({ name: el.name })),
          link:        item.link,
        };
        /* eslint-enable no-underscore-dangle */
      });
    },
    getThumbnailUrl(media) {
      // 401エラーが返ってくる&size以下がない場合があるので、複数から取得する
      if (media?.media_details?.sizes?.medium?.source_url) return media.media_details.sizes.medium.source_url;
      // sizeがない場合はsource_urlを取得
      if (media?.source_url) return media.source_url;
      return NoImage;
    },

    removeHtmlTag(textContent) {
      return textContent.replace(/(<([^>]+)>)/gi, '');
    },

    formatPubDate(dateString) {
      const date = new Date(dateString);
      return `${date.getFullYear()}
        .${String(date.getMonth() + 1).padStart(2, '0')}
        .${String(date.getDate()).padStart(2, '0')}`;
    },
  },
  filters: {
    formatTime: FormatTime,
  },
};
