<template>
  <div v-if="top10Articles.length > 0" class="blog-card">
    <h2>{{ header }}</h2>
    <ul class="blog-card-list">
      <li v-for="(item, key) in top10Articles" :key="key" class="simple">
        <a
          :href="item.link"
          class="top-useful-category-card"
          @click="send_click_event(item)"
        >
          <figure class="blog-card-img d-f jc-c ai-c f-1">
            <!-- TODO:: first-of-typeだけimgは1200pxの大きい方を読み込む。速度上げるため、他は300pxの小さい画像 -->
            <img
              :src="item.image"
              :alt="item.title"
              loading="lazy"
              :width="key === 0 ? '1200px' : '300px'"
              :height="key === 0 ? '630px' : '158px'"
            >
          </figure>
          <div class="flow pd-m block">
            <div class="f-1 fw-w label-wrap">
              <span class="c-primary d-c font-14--b">
                {{ Object.values(item.labels).filter(label => label.name).map(label => label.name).join(' / ') }}
              </span>
            </div>
            <p
              :class="[
                'lines-2',
                key === 0 ? 'font-20--b' : 'font-16--b'
              ]"
            >
              {{ item.title }}
            </p>
            <!-- 説明を表示するのは一つ目の記事だけ -->
            <p
              v-if="key === 0"
              class="lines-4"
            >
              {{ item.description }}
            </p>
          </div>
        </a>
      </li>
    </ul>
    <div class="link--r">
      <a href="https://www.itreview.jp/labo" target="_blank">すべての「ITreview Labo」の記事を見る</a>
    </div>
  </div>
</template>

<script>
  import BlogFeed from '../blog_feed';
  import { sendTrackingEvent } from '../../../entry/front/data_emitter';

  export default {
    mixins: [BlogFeed],
    props:  {
      header: { type: String, required: false, default: '' },
    },
    data() {
      return {
        feedsArray:    [],
        priorityFeeds: [],
      };
    },
    computed: {
      top10Articles() {
        return this.priorityFeeds.slice(0, 7);
      },
      getCategories() {
        return this.priorityFeeds
          .filter((v1, i1, a1) => a1.findIndex(v => v1.labels[0].name === v.labels[0].name) === i1);
      },
    },
    watch: {
      feeds() {
        this.feedsArray.push(this.feeds);
        if (this.feedsArray.length === 1) {
          this.get_priority_feed();
        } else if (this.feedsArray.length > 1) {
          this.merge_feeds();
        }
      },
    },
    methods: {
      get_priority_feed() {
        this.baseUrl = `${window.location.origin}/blog/archives/tag/pickup/feed`;
        this.get_feed();
      },
      merge_feeds() {
        const feedByTitle = {};
        this.feedsArray.reverse().forEach((feeds) => {
          feeds.forEach((feed) => {
            if (feed.title in feedByTitle) return;
            feedByTitle[feed.title] = feed;
          });
        });
        this.priorityFeeds = Object.values(feedByTitle);
      },
      send_click_event(item) {
        // ITreviewLaboの記事
        const { title } = item;
        const labelNames = Object.keys(item.labels).map(e => item.labels[e].name).filter(name => !!name).join(',');
        sendTrackingEvent('Introduction Guide Articles', `${labelNames} / ${title}`, 'click');
      },
    },
  };
</script>
