import Moment from 'moment';

Moment.locale('ja');

const formats = {
  default: 'YYYY年MM月DD日(dd) HH時mm分ss秒',
  long:    'YYYY/MM/DD HH:mm',
  short:   'MM/DD HH:mm',
  date:    'YYYY/MM/DD',
  feed:    'MMMM DD. YYYY',
  jpDate:  'YYYY年MM月DD日',
  time:    'HH:mm',
};

export default function (date, format) {
  let dateFormat = format;
  if (!dateFormat) dateFormat = 'default';
  else if (dateFormat === 'feed') Moment.locale('en');
  return Moment(date).format(formats[dateFormat]);
}
